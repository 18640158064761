import '../css/about.css';
import T from '../lib/translator';

const Android = () => (
    <div className="aboutContainer">
        <div className="about">
            <div className="et_post_meta_wrapper">
                <h1 className="entry-title">{T('Приложение под Android')}</h1>
                <p><a style={{textDecoration: 'none', color: '#024959'}} href="/app/uz.kitobplus.app_0.2.5.apk"><div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><img style={{border: '1px solid', borderColor: 'lightgray', borderRadius: 8, width: 32, marginRight: 8}} src="https://kitobplus.uz/logo512x512.png"/><div style={{flex: 1}}>{T('Скачать приложение Kitob Plus для Android')}.</div></div></a></p>
            </div>
        </div>
    </div>    
);

export default Android;