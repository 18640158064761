import React from 'react';
import { useLocation, useParams } from "react-router-dom";
import Player from "./player";
import '../css/personPage.css';
import Lead from "./lead";
import T from "../lib/translator";
import Book from './book';
import {get as getPerson} from '../lib/person';

const PersonPage = ({table, id}) => {
    const location = useLocation();
    const params = useParams();
    const [books, setBooks] = React.useState(null);
    const [ person, setPerson ] = React.useState({});

    React.useEffect(() => {
        console.log(params.table);
        console.log(params.id);
        if (params.table && params.id) {            
            const loadBooks = async () => {
                fetch('https://kitobplus.uz/srv/books.php?' + params.table + '_id=' + params.id).then((_) => _.json()).then((_) => {
                    let books = _.data;
                    setBooks(books);
                });
            };

            const loadPerson = async () => getPerson(params.table, params.id).then(_ => setPerson(_)).catch(_ => alert(_));

            loadPerson();
            loadBooks();
        }
    }, [params]);

    return (
        <>
            <Lead title={T('Библиотека') + ' > ' + (params.table === 'authors' ? T('Авторы') : (params.table === 'dictors' ? T('Дикторы') : T('Жанры')))}/>
            <div className="bookPage">
                <div className="bookPageDetail">
                    <div style={{display: 'flex'}}>
                        <div style={{marginLeft: 16, marginTop: 16, marginRight: 32}} >
                            <img className="personImage" alt="Изображение" src={'https://kitobplus.uz/pics/' + (person?.pics_ext ? params.table + '/' + person?.id + '.' + person?.pics_ext : 'book.png')}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <h2 style={{fontFamily: 'Segoe UI', marginLeft: 0, marginTop: 24, fontSize: 28, marginBottom: 0}}>{person?.name}</h2>
                            <div className="bookPageAnnotation">
                                {person?.annotation}
                            </div>
                        </div>
                    </div>

                    {(books && books.length)
                    ? (
                        <div style={{marginLeft: 16}}>
                            <h3 style={{marginLeft: 16, marginTop: 32}}>{T('Книги')}</h3>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {books.map((book) => <Book key={book.id} book={book}/>)}
                            </div>
                        </div>
                    ) : (<></>)
                    }
                </div>
            </div>
        </>
    );
}

export default PersonPage;
