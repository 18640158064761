import './App.css';
import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Routes
} from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Menu from './components/Menu';
import T from './lib/translator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import Library from './components/library';
import About from './components/about';
import BookPage from './components/BookPage';
import Authors from './components/authors';
import Dictors from './components/dictors';
import PersonPage from './components/PersonPage';
import Genres from './components/genres';
import GenrePage from './components/GenrePage';
import Login from './components/login';
import { getToken } from './lib/api';
import Bookshelf from './components/bookshelf';
import UserMenu from './components/usermenu';
import Profile from './components/profile';
import Register from './components/register';
import Books from './components/books';
import { getCountryCode } from './lib/api';
import Android from './components/android';
import Counter from './components/counter';

function App() {
  const [logged, setLogged] = React.useState(false);
  
  useEffect(() => {
      //document.title = 'KitobPlus';
      if (localStorage.getItem('token')) {
          setLogged(true);
      }
  })

  function login() {
      setLogged(true);
  }

  function logout() {
      localStorage.removeItem('token')
      setLogged(false);
  }
   
  return (
      <Router basename={'/'}>
        <div className="App">
          <header className="App-header">
              <nav>
                  <Link aria-label={T('В начало')} to="/"><img className="logo" src="https://kitobplus.uz/images/logo2.png"/></Link>
                  <ul>
                      <li>
                          <Link to="/">{T('Библиотека')}</Link>
                      </li>
                      <li>
                          <Link to="/authors">{T('Авторы')}</Link>
                      </li>
                      <li>
                          <Link to="/dictors">{T('Дикторы')}</Link>
                      </li>
                      <li>
                          <Link to="/genres">{T('Жанры')}</Link>
                      </li>
                      <li>
                          <Link to="about">{T('О нас')}</Link>
                      </li>
                  </ul>
              </nav>
              <div className="user">
                <UserMenu/>
              </div>
          </header>
          <main>
            <Routes>
              <Route path="/" element={<Library />} />
              <Route path="/books/all/:sort" element={<Books/>} />
              <Route path="/books/:id" element={<BookPage/>} />
              <Route path="/books" element={<Books/>} />
              <Route path="/authors" element={<Authors/>} />
              <Route path="/dictors" element={<Dictors/>} />
              <Route path="/persons/:table/:id" element={<PersonPage/>} />
              <Route path="/genres" element={<Genres/>} />
              <Route path="/genres/:id" element={<GenrePage/>} />
              <Route path="about" element={<About />} />
              <Route path="android" element={<Android />} />
              <Route path="login" element={<Login />} />
              <Route path="bookshelf" element={<Bookshelf />} />
              <Route path="register" element={<Register />} />
              <Route path="profile" element={<Profile />} />
            </Routes>
          </main>
          <footer>
            <div className='contacts'>
              <div style={{display: 'flex', alignItems:'center'}}><a style={{textDecoration: 'none', color: 'white', fontWeight: 'bold'}} href="https://t.me/kitobplus_support"><div style={{display: 'flex', height: 24, alignItems:'center'}}><img style={{height: 24, marginRight: 8 }} src="/images/telegram.webp"/>{T('Поддержка')}</div></a></div>
              <div>{T('Узбекистан')}, {T('Ташкент')}, +998 (90) 808-73-31</div>
            </div>
            <div className='apps'>
              <div>
                <a aria-label={T('Приложение под iOS')} href="https://apps.apple.com/us/app/kitobplus/id6470842246"><img title={T('Приложение под iOS')} style={{height: 32,paddingRight: 8}} src="https://kitobplus.uz/images/appstore.png"/></a>
                <a aria-label={T('Приложение под Android')} href="https://play.google.com/store/apps/details?id=uz.kitobplus.app"><img title={T('Приложение под Android')} style={{height: 32,paddingLeft: 8}} src="https://kitobplus.uz/images/googleplay.png"/></a>
              </div>
            </div>
            <a className="sharoit" href="https://sharoitplus.uz">
              <img src="/images/sharoidplus_transparent.png"/>
              {getCountryCode() === 'RU' ? <>{T('Проект')}<br/>Sharoit Plus</> : <>Sharoit Plus<br/>{T('Проект')}</>}
              <div style={{width: 0}}></div>
            </a>
          </footer>
        </div>
      </Router>
  );
}

export default App;
