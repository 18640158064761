import React from 'react';
import Comment from './comment';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { registered } from '../lib/api';
import T from '../lib/translator';
import '../css/comments.css';
import {add as addComment, remove as removeComment, get as getComments} from '../lib/comments.ts';
import EditComment from './comment_edit.js';

const Comments = ({books_id}) => {
    const [data, setData] = React.useState([]);
    const tfref = React.useRef();
    const [show, setShow] = React.useState(false);
    const [id, setId] = React.useState(0);
    const [comment, setComment] = React.useState('');

    React.useEffect(() => {if (books_id) {load(books_id);};}, [books_id]);

    const load = (books_id) => {
        getComments(books_id).then(_ => setData(_));
    };

    const updateComment = () => {
        addComment(id, books_id, comment).then(_ => {setShow(false); setData(_);}).catch(_ => alert(_));
    };

    const _removeComment = () => {
        removeComment(id, books_id, comment).then(_ => {setShow(false); setData(_);}).catch(_ => alert(_));
    };

    const edit = (id, comment) => {
        setId(id);
        setComment(comment);
        setShow(true);
        setTimeout(_ => tfref.current.focus(), 100);
    };

    return <div style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>        
        <div style={{display: 'flex', alignItems: 'stretch', flexWrap: 'wrap'}}>            
            {registered() && <div><Button aria-label={T("Добавить комментарий")} onClick={_ => edit(0, '')}><AddCommentIcon sx={{fontSize: 32, color: "#ffb27d"}}/></Button></div>}
            {data.map(c => <Comment edit={edit} comment={c} key={c.id} />)}
        </div>
        {registered() && <EditComment tfref={tfref} id={id} comment={comment} setComment={setComment} show={show} setShow={setShow} updateComment={updateComment} removeComment={_removeComment}/>}
    </div>
};


export default Comments;