import React from 'react';
import useSWR from 'swr';
import '../css/books.css';
import Book from './book';
import Lead from './lead';
import { LinearProgress } from '@mui/material';
import T from '../lib/translator';
import { useDebounce } from 'use-debounce';
import {Select} from '@mui/material';
import {MenuItem} from '@mui/material';
import {InputLabel} from '@mui/material';
import { useParams } from 'react-router-dom';

const Books = ({defaultFilter, defaultSort, embedded=false}) => {
    const [ sort, setSort ] = React.useState();
    const [ filter, setFilter ] = React.useState();
    const [ search, setSearch ] = React.useState(undefined);
    const [ searchRequest ] = useDebounce(search, 1000);
    const [ isLoading, setIsLoading ] = React.useState(false);
    const params = useParams();

    const fetcher = (...args) => fetch(...args).then(res => res.json()).then(_ => _.data);
    const { data, error, isBooksLoading} = useSWR('https://kitobplus.uz/srv/books.php?filter=' + (embedded ? defaultFilter : (searchRequest ?? '')) + '&sort=' + (sort ?? ''), fetcher)
    React.useEffect(() => {
        setSort(defaultSort);
        setFilter(defaultFilter);
    }, [defaultFilter, defaultSort]);
    
    React.useEffect(() => {
        setSort(params.sort);
    }, [params]);
    
    return <>
        {!embedded && <Lead title={T('Книги')} setSearch={setSearch}/>}
        <div className="search">
        {!embedded && <>
            <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between'}}>
                <h2><span style={{color: '#777'}}>{(searchRequest ? T('Поиск') :(T('Все книги')  + (data ? ' (' + data.length + ')' : '')))}</span>{searchRequest ? ' ' + searchRequest : ''}</h2>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', color: '#777'}}>
                    <InputLabel id="sort-label">Сортировать по</InputLabel>
                    <div style={{width: 16}}/>
                    <Select labelId='sort-label' id='sort-by' value={sort ?? 'name'} onChange={_ => setSort(_.target.value)}>
                        <MenuItem value='name'>Наименованию</MenuItem>
                        <MenuItem value='new'>Новизне</MenuItem>
                        <MenuItem value='popular'>Популярности</MenuItem>
                        <MenuItem value='recommend'>Рекомендациям</MenuItem>
                    </Select>
                </div>
            </div>
            <div className="searchProgress">
            <LinearProgress
                variant={((isLoading && searchRequest) || isBooksLoading) ? 'indeterminate' : 'determinate'}
                value={((isLoading && searchRequest) || isBooksLoading) ? undefined : 0}
                sx={{
                backgroundColor: "#00aec7",
                '& .MuiLinearProgress-bar': {
                    backgroundColor: '#ffb27d'
                }
            }}/>
            </div>
        </>}
            {data && data.length ?
                <div className="books">
                    {data && data.map((item) => <Book key={item.id} book={item} />)}
                </div>
            :
                <span tabIndex={0}  style={{marginLeft: 8, marginTop: 6}}>{T('Ничего не найдено') + ' :('}</span>
            }
        </div>
    </>;
};

export default Books;