import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useSWR from 'swr'
import '../css/personspicks.css';
import Person from './person';

const PersonsPicks = ({table, title, sort}) => {
    const fetcher = (...args) => fetch(...args).then(res => res.json()).then(_ => _.data);

    const { data, error, isLoading } = useSWR('https://kitobplus.uz/srv/persons.php?table=' + table + '&sort=' + sort, fetcher)

    React.useEffect(() => {

    }, []);
    
    return <div className="personsPicks">
        <div className="bpHead"><h2>{title}</h2>{isLoading && <CircularProgress size={28} sx={{marginTop: '4px', color: "#ffb27d"}}/>}</div>
        <div className="bpBody">
            {data && data.slice(0, 5).map((item) => <Person key={item.id} table={table} person={item} />)}
        </div>
    </div>
};

export default PersonsPicks;