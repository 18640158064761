import {useRef} from 'react';
import { getToken, getUser, registered } from "../lib/api";
import { Link } from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import T from "../lib/translator";
import LanguageSelector from './languageselector';
import { Button } from '@mui/material';

const UserMenu = () => {
    return <>
        {getToken() ? (
            (<>
                {registered() ? 
                    <Link title={T('Книжная полка')} style={{margin: 0}} aria-label={T('Книжная полка')} to="/bookshelf"><h3><img src="https://kitobplus.uz/images/bookshelf_active.png" style={{height: 32}} alt={T('Книжная полка')} /></h3></Link>
                : <></>}
                <Link title={T('Профиль')} style={{marginRight: 8}} aria-label={T('Профиль')} to="/profile"><h3><img src="https://kitobplus.uz/images/user_active.png" style={{height: 32}} alt={T('Профиль')} /></h3></Link>            
            </>)
        ) :
            <><LoginIcon sx={{fontSize: 32, color: "#ffb27d"}} /> <Link to="/login" style={{textTransform: 'uppercase'}}>{T('Вход')}</Link></>
        }
        <LanguageSelector/>
    </>
}

export default UserMenu;
