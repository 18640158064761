import React from 'react';
import T from '../lib/translator';

const Selector = ({selector, setSelector, options}) => {
    return <div style={{justifySelf: 'center'}}>{T('Показать книги') + ' '}
        {options.map((option, i) => <button
            key={i}
            aria-label={T('Показать книги') + ' ' + option.title + (selector === option.value ? ' (' + T('выбрано') + ')' : '')}
            data-value1={'test'}
            onClick={_ => setSelector(options[i].value)}
            style={{
                padding: 6,
                backgroundColor: (selector === option.value ? '#024959' : 'transparent'),
                color: (selector === option.value ? 'white' : '#024959'),
                borderTopLeftRadius: (i ? 0 : 6),
                borderBottomLeftRadius: (i ? 0 : 6),
                borderColor: 'gray',
                borderWidth: 1,
                borderTopRightRadius: (i === options.length - 1 ? 6 : 0),
                borderBottomRightRadius: (i === options.length - 1 ? 6 : 0),
            }}
        >{option.title}</button>)}
    </div>;
};

export default Selector;