import React from 'react';
import '../css/person.css';
import { Link } from 'react-router-dom';
import T from '../lib/translator';

const Person = ({table, person}) => {
    return (
        <Link style={{textDecoration: 'none'}} to={"/persons/" + table + "/" + person.id} state={{ person: person }} >
            <div className="person">
                <img className="personImage" alt="Изображение" src={'https://kitobplus.uz/pics/' + (person.pics_ext ? table + '/' + person.id + '.' + person.pics_ext : 'book.png')}/>
                <div className="personName">
                    <div className="personTitle">{person.name}</div>
                    <div className="personBooksCount">
                        <span style={{color: '#999'}}>{T('Книг')} - </span>
                        <span style={{color: '#555', fontFamily: 'Segoe UI Gras', fontWeight: 'bold'}}>{person.books_count}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
};

export default Person;