import React from 'react';
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import T from '../lib/translator';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import '../css/form.css';
import { Button, MenuItem, InputLabel, Select } from '@mui/material';
import { getUser} from '../lib/auth.ts';
import { logout } from '../lib/api.js';
import {CircularProgress} from '@mui/material';
import {getCountryCode, setCountryCode as saveCountryCode} from '../lib/api.js';

const Profile = () => {
    const [loading, setLoading] = React.useState(true);
    const [user, setUser] = React.useState({})
    const [countryCode, setCountryCode] = React.useState();

    React.useEffect(() => {
        getUser().then(_ => {
            setUser(_);
            setLoading(false);
        }).catch(_ => window.location = '/');

        setCountryCode(getCountryCode());
    }, []);

    const states = [T('Незарегистрированный пользователь'),T('Ожидает подтверждения регистрации'), T('Доступ подтверждён'), T('В регистрации отказано')];

    const exit = () => {
        logout();
        window.location = '/login';
    }

    return (
        <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div className='form'>
                <div className='formHeader'>
                    <h1>{T('Профиль')}</h1>
                </div>
                {loading ?
                    <div style={{margin: 32, display: 'flex', flex: 1, justifyContent: 'center'}}><CircularProgress /></div>
                :(<>   
                    <div className='formBody'>
                        <table className="profile" border={0}>
                            <tbody>
                                <tr><td>{T('ФИО')}</td><td><strong>{user?.name}</strong></td></tr>
                                <tr><td>{T('Телефон')}</td><td><strong>{user?.phone}</strong></td></tr>
                                <tr><td>{T('Дата регистрации')}</td><td><strong>{user?.register_date.replace(/^(\d\d\d\d)-(\d\d)-(\d\d).*$/, '$3.$2.$1')}</strong></td></tr>
                                <tr><td>{T('Текущий статус')}</td><td><strong>{states[user.state]}</strong></td></tr>
                            </tbody>
                        </table>
                    </div>
                    {user?.state === 2 && <div className='formFooter'><Button variant="contained" color='primary' onClick={exit}>{T('Выход')}</Button></div>}
                </>)}
<div style={{display: 'none'}}>
                <br/>
                <div className='formHeader'>
                    <h1>{T('Настройки')}</h1>
                </div>
                <div className='formBody'>
                    <table className="profile" border={0}>
                        <tbody>
                            <tr><td width='50%'><InputLabel id="country_code-label">{T('Язык приложения')}</InputLabel></td><td style={{padding: 0}} align='right'>
                                <Select labelId="country_code-label" id="country_code" value={countryCode ?? 'UZ'} onChange={_ => {saveCountryCode(_.target.value); setCountryCode(_.target.value);}}>
                                    <MenuItem value='UZ'><strong>🇺🇿 узбекский</strong></MenuItem>
                                    <MenuItem value='RU'><strong>🇷🇺 русский</strong></MenuItem>
                                    <MenuItem value='EN'><strong>🇺🇸 английский</strong></MenuItem>
                                </Select></td></tr>
                        </tbody>
                    </table>
                </div>
</div>
            </div>
        </div>
    );
}

export default Profile;