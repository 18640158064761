import React from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { registered } from '../lib/api';
import T from '../lib/translator';
import '../css/comments.css';

const EditComment = ({tfref, id, comment, setComment, show, setShow, updateComment, removeComment}) =>
    <div className="comment" style={{display: show ? 'inline-block': 'none'}}>
        <TextField 
            inputRef={tfref}
            sx={{width: 512}}
            required={true}
            label={T('Комментарий')}
            variant="outlined"
            value={comment}
            multiline
            onChange={event => setComment(event.target.value)}
        />
        <div style={{display: 'flex', flex: 1, justifyContent: 'space-between', width: 512}}>
            <div style={{display: 'flex'}}>
                {id !== 0 && <div style={{display: 'flex'}}><Button onClick={_ => removeComment(id)} variant="outlined" color="error">{T('Удалить комментарий')}</Button></div>}
            </div>
            <div style={{display: 'flex'}}>
                <div style={{display: 'flex'}}><Button onClick={updateComment} variant="contained" color='primary' >{T('Сохранить')}</Button></div>
                <div style={{width: 8}}/>
                <div style={{display: 'flex'}}><Button onClick={_ => setShow(false)} variant="outlined">{T('Отмена')}</Button></div>
            </div>
        </div>
    </div>

export default EditComment;