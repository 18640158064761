import React from 'react';
import T from '../lib/translator';
import Lead from './lead';
import LinearProgress from '@mui/material/LinearProgress';
import { useDebounce } from 'use-debounce';
import useSWR from 'swr';
import Person from './person';

const Persons = ({table, embedded = false, filter}) => {
    const [ search, setSearch ] = React.useState(undefined);
    const [ searchRequest ] = useDebounce(search, 1000);
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ query, setQuery] = React.useState('');
    const [ data, setData ] = React.useState([]);
    const [ isGenresLoading, setIsGenresLoading ] = React.useState(false);
    
    React.useEffect(() => {
        //setQuery(searchRequest ? '?filter=' + encodeURIComponent(searchRequest) : '');
        fetch('https://kitobplus.uz/srv/persons.php?table=' + table + '&filter=' + encodeURIComponent(embedded ? filter : searchRequest)).then(_ => _.json()).then(_ => {
            console.log(_.data);
            setData(_.data);
            setIsLoading(false);
        });
    }, [searchRequest, filter]);

    React.useEffect(() => {
        setIsLoading(true);
    }, [search]);

    //const fetcher = (...args) => fetch(...args).then(res => {console.log(res); return res.json();}).then(_ => {console.log(_.data); return _.data;});
    //const { data, error, isGenresLoading } = useSWR(['https://kitobplus.uz/srv/genres.php', query], fetcher);

    
    return (<>
        {!embedded &&  <Lead title={T('Жанры')} setSearch={setSearch}/>}
            <div className="search">
                {!embedded ?? <>
                    <h2><span style={{color: '#777'}}>{searchRequest ? T('Поиск') : T('Все жанры')}</span>{searchRequest ? ' ' + searchRequest : ''}</h2>
                    <div className="searchProgress">
                    <LinearProgress
                        tabIndex={isLoading ? 0 : undefined}
                        aria-label={T('Выполняется поиск')}
                        variant={((isLoading && searchRequest) || isGenresLoading) ? 'indeterminate' : 'determinate'}
                        value={((isLoading && searchRequest) || isGenresLoading) ? undefined : 0}
                        sx={{
                        backgroundColor: "#00aec7",
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#ffb27d'
                        }
                    }}/>
                    </div>
                </>}
                {data.length ?
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {data.map((person) => <Person key={person.id} table={table} person={person}/>)}
                    </div>
                :
                    <span tabIndex={0}  style={{marginLeft: 8, marginTop: 6}}>{T('Ничего не найдено') + ' :('}</span>
                }
            </div>
    </>);
};


export default Persons;