import React from 'react';
import T from '../lib/translator';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Button, MenuItem, InputLabel, Select } from '@mui/material';
import {getCountryCode, setCountryCode as saveCountryCode} from '../lib/api.js';
import { styled, alpha } from '@mui/material/styles';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const LanguageSelector = () => {
    const [countryCode, setCountryCode] = React.useState(getCountryCode());
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    //const [open, setOpen] = React.useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const l = (_) => {
        saveCountryCode(_);
        setCountryCode(_);
        setAnchorEl(null);
        window.location.reload();
    };

    const getFlag = (cc) => {
        if (cc === 'RU') return '🇷🇺';
        if (cc === 'EN') return '🇺🇸';
        return '🇺🇿';
    }

    return <>
      <Button
        id="language-button"
        aria-label={T('Язык приложения')}
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
                    disableElevation
        onClick={handleClick}       
        style={{fontSize: 24,
            padding: 0,
            margin: 0,
            marginBottom: -6
        }}
      >
        {getFlag(countryCode)}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}        
      >
        <MenuItem onClick={_ => l('UZ')} value='UZ'><strong>🇺🇿 o'zbek</strong></MenuItem>
        <MenuItem onClick={_ => l('RU')} value='RU'><strong>🇷🇺 русский</strong></MenuItem>
        <MenuItem onClick={_ => l('EN')} value='EN'><strong>🇺🇸 english</strong></MenuItem>
      </StyledMenu>
    </>
}

export default LanguageSelector;