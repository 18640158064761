import React from 'react';
import { useLocation, useParams } from "react-router-dom";
import '../css/genrePage.css';
import Lead from "./lead";
import T from "../lib/translator";
import Book from './book';
import {get as getPerson} from '../lib/person';

const GenrePage = () => {
    const location = useLocation();
    const params = useParams();
    const [genre, setGenre] = React.useState({});
    const [books, setBooks] = React.useState(null);

    React.useEffect(() => {
        console.log(params.id);
        if (params.id) {            
            const loadBooks = () => {
                fetch('https://kitobplus.uz/srv/books.php?genres_id=' + params.id).then((_) => _.json()).then((_) => {
                    let books = _.data;
                    setBooks(books);
                });
            };
            const loadGenre = async () => {
                getPerson('genres', params.id).then(_ => {setGenre(_);}).catch(_ => alert(_));
            };

            loadGenre();
            loadBooks();
        }
    }, [params]);

    return (
        <>
            <Lead title={T('Библиотека') + ' > ' + T('Жанры')}/>
            <div className="bookPage">
                <div className="bookPageDetail">
                    <div style={{display: 'flex'}}>
                        <div style={{marginLeft: 16, marginTop: 16, marginRight: 32}} >
                            <img className="genreImage" alt="Изображение" src={'https://kitobplus.uz/pics/' + (genre?.pics_ext ? 'genres/' + genre?.id + '.' + genre?.pics_ext : 'book.png')}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <h2 style={{fontFamily: 'Segoe UI', marginLeft: 0, marginTop: 24, fontSize: 28, marginBottom: 0}}>{genre?.name}</h2>
                            <div className="bookPageAnnotation">
                                {genre?.annotation}
                            </div>
                        </div>
                    </div>

                    {(books && books.length)
                    ? (
                        <div style={{marginLeft: 16}}>
                            <h3 style={{marginLeft: 16, marginTop: 32}}>{T('Книги')}</h3>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {books.map((book) => <Book key={book.id} book={book}/>)}
                            </div>
                        </div>
                    ) : (<></>)
                    }
                </div>
            </div>
        </>
    );
}

export default GenrePage;
