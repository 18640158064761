import { getCountryCode } from './api';
import translation from './translation.json';

//const translation: any = 

const T = (verb: string) => {
    //console.log("T(verb...");
    let result = verb;
    try {
        result = _T(verb);
    } catch(error) {
        return verb;
    };

    //console.log("T(" + (getCountryCode() ?? 'UZ') + ", " + verb + ") = " + result);
    return result;
};

const _T = (verb: string) => {
    let code = getCountryCode() ?? 'UZ';
    //let code = 'UZ';
    //let code = 'RU';
    
    if (code === 'RU') {
        return verb;
    }

    let error = null;
    if (!translation.hasOwnProperty(verb)) {
        error = 'NF';
    }

    if (!error && translation.hasOwnProperty(verb) && translation[verb] !== undefined && !translation[verb].hasOwnProperty(code)) {
        error = 'EC';
    }

    if (error) {
        //return verb;
        //return "[" + error + "] " + verb;
        //request('translation/add', {'code': getCountryCode() ?? 'UZ', 'ru': verb}).then((_)=>{}).catch((error) => Alert.alert('Error while save translation verb ' + verb, error));
        return verb;
    }


    return translation[verb][code];
};

export default T;
