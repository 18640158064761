import React from 'react';
import T from '../lib/translator';
import Lead from './lead';
import BookPicks from './bookpicks';
import LinearProgress from '@mui/material/LinearProgress';
import { useDebounce } from 'use-debounce';
import { Link } from 'react-router-dom';
import Books from './books';

const Library = () => {
    const [ search, setSearch ] = React.useState(undefined);
    const [ searchRequest ] = useDebounce(search, 1000);
    const [ isLoading, setIsLoading ] = React.useState(false);
    
    React.useEffect(() => {
        setIsLoading(false);
    }, [searchRequest]);

    React.useEffect(() => {
        setIsLoading(true);
    }, [search]);

    return (<>
        <Lead title={T('Библиотека')} setSearch={setSearch}/>
        {searchRequest ?
            (<>
                <div className="search">
                    <h2><span style={{color: '#777'}}>{T('Поиск')}</span>{' ' + search}</h2>
                    <div className="searchProgress">
                    <LinearProgress
                        variant={isLoading ? 'indeterminate' : 'determinate'}
                        value={isLoading ? undefined : 0}
                        sx={{
                        backgroundColor: "#00aec7",
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#ffb27d'
                        }
                    }}/>
                    </div>
                </div>
                <Books defaultFilter={searchRequest} embedded={true}/>
            </>) : (
                <>
                    <BookPicks sort='new' title={T('Новинки')} />
                    <BookPicks sort='popular' title={T('Популярное')} />
                    <BookPicks sort='recommend' title={T('Рекомендуем')} />
                    <Link style={{textDecoration: 'none'}} to="books"><h3 style={{color: '#777'}}>{T('Все книги')}</h3></Link>
                </>
            )
        }
    </>);
};


export default Library;