import React from 'react';
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import T from '../lib/translator';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import '../css/form.css';
import { Button } from '@mui/material';
import { sms, verify } from '../lib/auth.ts';

const Login = () => {
    const [value, setValue] = React.useState()
    const [isCorrect, setIsCorrect] = React.useState()
    const [smsSended, setSmsSended] = React.useState(false)
    const [debugCode, setDebugCode] = React.useState();
    const [error, setError] = React.useState()
    const [code, setCode] = React.useState('')

    React.useEffect((_) => {
        if (value) {
            setIsCorrect(isPossiblePhoneNumber('+' + value));
        } else {
            setIsCorrect(false);
        }
    }, [value]);

    const login = () => {
        if (!isCorrect) {
            alert(T('Введен некорректный номер телефона'));
            return;
        }

        if (!smsSended) {
            sms(value).then(_ => {                
                console.log(_);
                setDebugCode(_?.code);
                setSmsSended(true);
            }).catch(_ => alert(_));

            return;
        }
    };

    React.useEffect(() => {
        if (!code || code.length !== 4) {
            return;
        }

        verify(value, code).then(_ => {
            console.log(_);
            if (_.user.state === 0) {
                window.location = '/register';
            }
            if (_.user.state === 2) {
                alert(T('Добро пожаловать в библиотеку Kitob+.'));
                window.location = '/';
            }
            if (_.user.state === 1 || _.user.state === 3) {
                window.location = '/profile';
            }
        }).catch(_ => setError(_));
    }, [code]);

    return (
        <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div className='form'>
                <div className='formHeader'>
                    <h1>{T('Регистрация/вход')}</h1>
                </div>
                {!smsSended ? (<>
                    <div className='formBody'>
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true
                            }}
                            specialLabel={T('Телефон')}
                            placeholder={T('Введите номер телефона')}
                            value={value}
                            disableDropdown={true}
                            onChange={setValue}/>
                    </div>
                    <div className='fromFooter'>
                        <Button variant="contained" color='primary' onClick={login}>{T('Продолжить')}</Button>
                    </div>
                </>) : (<>
                    <div className='formBody'>
                        <div>{T('Мы отправили SMS с кодом на номер')}</div>
                        <div style={{fontWeight: 'bold'}}>+{value}</div>
                        <div>{T('Пожалуйста, введите код из SMS')}</div>
                        <div style={{display: 'flex', justifyContent: 'center', paddingTop: 16, paddingBottom: 16}}>
                            <input aria-label={T('Пожалуйста, введите код из SMS')} autoFocus={true} style={{fontSize: 32, textAlign: 'center', width: 128, borderRadius: 8, borderWidth: 1, borderColor: 'lightgray'}}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key) || (event.target.value && event.target.value.length >= 4)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={e => setCode(e.target.value)}
                                value={code}
                            />
                        </div>
                        {debugCode ? <div style={{display: 'flex', justifyContent: 'center'}}><span style={{color: 'green'}}>{T('Код')}: {debugCode}</span></div> : <></>}                        
                        {error ? <div style={{display: 'flex', justifyContent: 'center'}}><span style={{color: 'red'}}>{error}</span></div> : <></>}
                    </div>
                </>)}

            </div>
        </div>
    );
}

export default Login;