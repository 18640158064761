import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useSWR from 'swr'
import '../css/bookpicks.css';
import Book from './book';
import { Link } from 'react-router-dom';

const BookPicks = ({title, sort}) => {
    const fetcher = (...args) => fetch(...args).then(res => res.json()).then(_ => _.data);
    const { data, error, isLoading } = useSWR('https://kitobplus.uz/srv/books.php?sort=' + sort, fetcher)

    return <div className="bookPicks">
        <div className="bpHead"><h2><Link to={"books/all/" + sort}>{title}</Link></h2>{isLoading && <CircularProgress size={28} sx={{marginTop: '4px', color: "#ffb27d"}}/>}</div>
        <div className="bpBody">
            {data && data.slice(0, 5).map((item) => <Book key={item.id} book={item} />)}
        </div>
    </div>
};

export default BookPicks;