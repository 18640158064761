import React from 'react';
import '../css/genre.css';
import { Link } from 'react-router-dom';

const Genre = ({genre}) => {
    return (
        <Link style={{textDecoration: 'none'}} to={"/genres/" + genre.id} >
            <div className="genre">
                <img className="genreImage" alt="Изображение" src={'https://kitobplus.uz/pics/' + (genre.pics_ext ? 'genres/' + genre.id + '.' + genre.pics_ext : 'genre.png')}/>
                <div className="genreName">
                    <div className="genreTitle">{genre.name}</div>
                    <div className="genreBooksCount">
                        <span style={{color: '#999'}}>Книг - </span>
                        <span style={{color: '#555', fontFamily: 'Segoe UI Gras', fontWeight: 'bold'}}>{genre.books_count}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
};

export default Genre;