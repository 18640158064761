import '../css/about.css';

const About = () => (
    <div className="aboutContainer">
        <div className="about">
            <div className="et_post_meta_wrapper">
                <h1 className="entry-title">KitobPlus — онлайн-библиотека для людей с инвалидностью по зрению</h1>
                <p className="post-meta" ><span className="published" >Авг 1, 2023</span></p>
                <img style={{ height: 'auto', maxWidth: '100%'}} src="https://sharoitplus.uz/wp-content/uploads/2023/07/kitob-plus-1080x675.jpg" alt class width={1080} height={675} srcset="https://sharoitplus.uz/wp-content/uploads/2023/07/kitob-plus-1080x675.jpg 1080w, https://sharoitplus.uz/wp-content/uploads/2023/07/kitob-plus-980x616.jpg 980w, https://sharoitplus.uz/wp-content/uploads/2023/07/kitob-plus-480x302.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw"/>
            </div>
            <div className="entry-content">
                <p>
                    <em>Тифлокомментарий к фото: Менеджер проекта Мирали Гиёсов сидит перед микрофоном и компьютером в студии звукозаписи. У Мирали темные очки и он сидит в черном костюме с брюками, черным галстуком и белой рубашкой.</em>
                </p>
                <p>В настоящее время аудиокниги можно слушать в таких приложениях, как Asaxiy, Audionoma, UzBook. Но, во-первых, они платные, а во-вторых, данные программы не адаптированы для незрячих и слабовидящих пользователей. Цель этого проекта — создать веб-сайт, на котором в одном месте будут собраны все аудиокниги, а также разработать полностью доступное и бесплатное приложение для слабовидящих пользователей на платформах Android и iOS. В связи с этим наша команда начала свою работу под названием «KitobPlus».</p>
                <p>Аудиокниги и радиоспектакли имеют особое значение в повседневной жизни людей с инвалидностью по зрению, в их научной деятельности, в формировании их восприятия мира и людей. Изобретение радио и создание возможности звукозаписи породили появление аудиокниг. Такие книги сначала читали по радио, представляли на дисках, а в 50-х годах прошлого века размножали на магнитных лентах и ​​доставляли в библиотеки и на специализированные предприятия, где работают незрячие и слабовидящие люди.</p>
                <p>В нашу страну такие книги попали в 60-х годах прошлого века, а в период с 1970 по 2000 годы были озвучены и записаны на магнитные ленты сотни книг. С появлением компьютеров написание аудиокниг стало еще проще. Книги стали представлять на компакт-дисках. Но развитие технологий быстро сделало этот метод устаревшим, теперь книги хранятся и распространяются на флешках, в памяти телефона, на интернет-сайтах.</p>
                <p>Несмотря на исторические этапы развития аудиокниг, людям с инвалидностью по зрению приходилось ходить в библиотеки, получать их в аудиоформате, читать их и возвращать обратно. Люди, умеющие пользоваться компьютером, собирали эти книги на своих компьютерах и раздавали другим книголюбам. Сегодня аудиокниги стали настолько популярны, что их слушают все слои общества и не только люди с инвалидностью по зрению.</p>
                <p>Однако в Узбекистане до сих пор нет онлайн-платформы, которая бы объединяла эти книги для незрячих и слабовидящих читателей. Им приходится скачивать книги с YouTube, Telegram или по старинке, идя в библиотеку и скачивая их на свои устройства. На платформе YouTube доступно множество аудиокниг, но когда ты читаешь роман с удовольствием, реклама лезет и портит получается удовольствие от прослушивания книги. Кроме того, нет платформы, которая предлагает возможность поиска книг по жанрам, авторам, дикторам. В связи с этим наша команда начала свою работу под названием <strong>«KitobPlus»</strong> (на русском языке «КнигаПлюс» имеет значение <em>«доступная книга»</em>).</p>
                <p><strong>Название проекта:</strong> &#171;Kitob Plus — создание онлайн-библиотеки для людей с инвалидностью по зрению&#187;</p>
                <p><strong>Донор проекта:</strong> Агентство по делам молодежи при Министерстве молодежной политики и спорта Республики Узбекистан</p>
                <p><strong>Бюджет проекта:</strong> 232 608 116,88 сумов</p>
                <p><strong>Период реализации проекта:</strong> С 13 июля по 13 ноября 2023 г. (длительность проекта 4 месяца)</p>
                <p><strong>Партнеры проекта:</strong> <a href="https://www.av3715.ru/">AV3715 &#8212; Первая Интернациональная Онлайн-Библиотека для инвалидов по зрению «Логос»</a></p>
                <p><strong>Задачи проекта: </strong></p>
                <ol>
                    <li>Создание вебсайта онлайн-библиотеки для людей с инвалидностью по зрению на домене www.kitobplus.uz и мобильного приложения Kitob Plus, работающего синхронно с ним;</li>
                    <li>Аудиозапись 105 научно-художественных, документальных, популярных и других книг на узбекском языке;</li>
                    <li>Отбор не менее 500 аудиокниг, доступных на сегодняшний день, и размещение их на веб-сайте и в мобильном приложении, упомянутом выше. Улучшение качества аудиокниг низкого качества, переведенных с магнитных лент и кассет в цифровой формат;</li>
                    <li>Обработка и выгрузка не менее 300 радиоспектаклей на вебсайт/мобильное приложение;</li>
                    <li>Налаживание сотрудничества со студиями аудиокниг в Узбекистане и размещение их аудиокниг на платформе;</li>
                    <li>Обеспечение озвучивания книг с помощью синтезаторов речи.</li></ol><p><strong>Ожидаемые результаты от оказанных услуг:</strong></p>
                <ul>
                    <li>объединение всех бесплатных аудиокниг на узбекском языке, предоставление пользователям удобной возможности просматривать, слушать и скачивать аудиокниги;</li>
                    <li>предоставление возможности волонтерам размещать свои аудиокниги на платформе Kitob Plus;</li>
                    <li>популяризировать идею об актуальности и важности создания аудиокниг для незрячей и слабовидящей молодежи.</li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>Исполнители проекта: </strong></p>
                <p><strong>Менеджер проекта:</strong> Мирали Гиясов</p>
                <p><strong>Координатор проекта:</strong> Абдулазиз Салимов</p>
                <p><strong>Программист:</strong> Михаил Корнеев</p>
                <p><strong>Корректор:</strong> Ширин Ульмасова</p>
                <p><strong>Бухгалтер проекта:</strong> Ирода Нарзиева</p>
                <p>&nbsp;</p>
                <p>Ваши вопросы и предложения по проекту направляйте на электронный адрес <a href="mailto:m.giyosov@sharoitplus.uz">m.giyosov@sharoitplus.uz</a> или свяжитесь с нами по этому номеру телефона: +998 (97) 708-73-31.</p>
                <p>&nbsp;</p>
                <p>Если вы хотите внести свой вклад в реализацию этого проекта, вы можете поддержать нас, используя системы онлайн-платежей Click, Payme или Payze или став волонтером по ссылке: <a href="https://sharoitplus.uz/ru/podderjat/#donate-form">https://sharoitplus.uz/ru/podderjat/#donate-form</a></p>
            </div>
        </div>
    </div>    
);

export default About;