import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import T from '../lib/translator';
import { getUser } from '../lib/api';

const Comment = ({comment, edit}) => <div tabIndex="0" aria-label={comment.user + ", " + comment.dt + ". " + comment.comment} className="comment">
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div style={{color: 'gray', fontWeight: 'bold'}}>{comment.user}</div>
                <div style={{marginLeft: 16, marginRight: 12, color: 'gray'}}>{comment.dt}</div>
                {(getUser() && getUser().id == comment.users_id) &&
                        <Button onClick={_ => edit(comment.id, comment.comment)} aria-label={T('Редактировать')}>
                                <EditIcon color="primary" sx={{height: 16}}/>
                        </Button>
                }
        </div>
        <div style={{fontStyle: 'normal', whiteSpace: 'pre-wrap'}}>{comment.comment}</div>
</div>

export default Comment;