import React from 'react';
import T from '../lib/translator';
import { ThumbDownAlt, ThumbDownOffAlt , ThumbUpAlt, ThumbUpOffAlt} from '@mui/icons-material';
const BookActions = ({bookshelfed, saveBookshelfed, bookmarks, addBookmark, like, setLike}) => {
    return (
        <div style={{marginTop: 8, display: 'flex', justifyContent: 'space-evenly'}}>
            <button onClick={() => saveBookshelfed(!bookshelfed)} style={{borderWidth: 0, backgroundColor: 'transparent'}} aria-label={bookshelfed ? T('Снять с книжной полки') : T('Поставить на книжную полку')}>
                <img title={bookshelfed ? T('Снять с книжной полки') : T('Поставить на книжную полку')} style={{height: 48}} src={bookshelfed ? "https://kitobplus.uz/images/bookshelf_active.png" : "https://kitobplus.uz/images/bookshelf_inactive.png"}/>
            </button>
            <button onClick={addBookmark} style={{borderWidth: 0, backgroundColor: 'transparent'}} aria-label={T('Добавить закладку')}>
                <img style={{height: 48}} src={bookmarks.length ? "https://kitobplus.uz/images/bookmark_active.png" : "https://kitobplus.uz/images/bookmark_inactive.png"}/>
            </button>

            <button onClick={_ => setLike(like === 1 ? 0 : 1)} style={{borderWidth: 0, backgroundColor: 'transparent'}} aria-label={T('Нравится') + '  (' + (like === 1 ? T('отмечено') : T('не отмечено')) + ')'}>
                {like === 1 ? <ThumbUpAlt/> : <ThumbUpOffAlt/>}
            </button>

            <button onClick={_ => setLike(like === -1 ? 0 : -1)} style={{borderWidth: 0, backgroundColor: 'transparent'}} aria-label={T('Не нравится') + ' (' + (like === -1 ? T('отмечено') : T('не отмечено')) + ')'}>
                {like === -1 ? <ThumbDownAlt/> : <ThumbDownOffAlt/>}
            </button>

        </div>
    );
}

export default BookActions;