import React from 'react';
import { useLocation, useParams } from "react-router-dom";
import Player from "./player";
import '../css/bookPage.css';
import Lead from "./lead";
import T from "../lib/translator";
import Book from './book';
import { Link } from 'react-router-dom';
import { getToken } from '../lib/api';
import { useLocalStorage } from '@uidotdev/usehooks';
import { get as getPlaybackPosition } from '../lib/bookmarks.ts';
import { request } from '../lib/api.js';
import Comments from './comments.js';

const BookPage = ({id}) => {
    const location = useLocation();
    const [recomendations, setRecomendations] = React.useState([]);
    const [book, setBook] = React.useState({});
    const params = useParams();
    const [ready, setReady] = React.useState(false);

    React.useEffect(() => {
        console.log(book);
        if (book?.id) {
            loadRecomendations(book.id);
        };
    }, [book]);

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    React.useEffect(() => {
        if (params?.id) {
            request('books/get', {id: params.id}).then((_) => {
                console.log(_.data);
                setBook(_.data);
            }).finally(() => setReady(true));
        }
    }, [params]);

    const loadRecomendations = () => {
        fetch('https://kitobplus.uz/srv/books.php').then((_) => _.json()).then((_) => {
            let books = _.data.filter((_) => _.id.toString() != book?.id.toString());
            setRecomendations(shuffle(books).slice(0, 3));
        });
    };

    return (
        <>
            <Lead title={T('Библиотека') + ' > ' + T('Книги')}/>            
            <div className="bookPage">
                {(ready && book && book?.id) ? (<>
                    <Player book={book}/>
                    <div className="bookPageDetail">
                        <h2 style={{fontFamily: 'Segoe UI', marginLeft: 0, marginTop: 24, fontSize: 28, marginBottom: 0}}>{book?.title}</h2>
                        <div className="bookPagePersons">
                            <Link style={{textDecoration: 'none'}} to={'/persons/authors/' + book?.authors_id} state={{person: {id: book?.authors_id, name: book?.authors_name, books_count: 123}}}><div className="bookPageAuthor"><img src={'https://kitobplus.uz/pics/author' + (book?.authors_pics_ext ? 's/' + book?.authors_id + '.' + book?.authors_pics_ext : '.png')}/>{book?.authors_name}</div></Link>
                            <div style={{width: 32}}/>
                            <Link style={{textDecoration: 'none'}} to={'/persons/dictors/' + book?.dictors_id}><div className="bookPageDictor"><img src={'https://kitobplus.uz/pics/dictor' + (book?.dictors_pics_ext ? 's/' + book?.dictors_id + '.' + book?.dictors_pics_ext : '.png')}/> <span>{book?.dictors_name}</span></div></Link>
                        </div>
                        <div className="bookPageGenres">
                        {book?.genres.map((genre, i) => 
                            <Link key={genre.id} style={{textDecoration: 'none'}} to={'/persons/genres/' + genre.id}>
                                <div className="bookPageGenre">                        
                                    <img src={'https://kitobplus.uz/pics/genre' + (genre.pics_ext ? 's/' + genre.id + '.' + genre.pics_ext : '.png')}/> <span>{genre.name}</span>                        
                                </div>
                            </Link>
                        )}
                        </div>
                        <div className="bookPageAnnotation">
                            {book?.annotation}
                        </div>
                        <div className="bookPagePublisher">
                            {T('Издатель') + ' ' + book?.publishers_name}
                        </div>
                        <div className="bookPageComments" style={{marginTop: 12}}>
                            <Comments books_id={book.id}/>
                        </div>
                        {(recomendations && recomendations.length)
                        ? (
                            <div className="bookPageRecomendation">
                                <h3 style={{marginLeft: 0, marginTop: 12}}>{T('Если вам понравилась эта книга, то рекомендуем')}</h3>
                                <div style={{display: 'flex'}}>
                                    {recomendations.map((book) => <Book key={book.id} book={book}/>)}
                                </div>
                            </div>
                        ) : (<></>)
                        }
                    </div>
                </>) : <></>}
            </div>
        </>
    );
}

export default BookPage;
