import React from 'react';
import { useLocation, useParams } from "react-router-dom";
import '../css/genrePage.css';
import Lead from "./lead";
import T from "../lib/translator";
import Book from './book';
import {get as getPerson} from '../lib/person';
import { getToken } from '../lib/api';

const Bookshelf = () => {
    const location = useLocation();
    const params = useParams();
    const [books, setBooks] = React.useState(null);
    const [selector, setSelector] = React.useState('bookshelf');

    React.useEffect(() => {
        if (!getToken()) {
            window.location = '/';
            return;
        }

        const loadBooks = () => {
            fetch('https://kitobplus.uz/srv/books.php?token=' + getToken() + '&realm=' + selector).then((_) => _.json()).then((_) => {
                let books = _.data;
                setBooks(books);
            });
        };

        loadBooks();
    }, [selector]);

    return (
        <>
            <Lead title={T('Книжная полка')} selector={selector} setSelector={setSelector}/>
            <div className="bookPage">
                <div className="bookPageDetail">
                    {(books && books.length)
                    ? (
                        <div style={{marginLeft: 16, marginTop: 16}}>
                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                {books.map((book) => <Book key={book.id} book={book}/>)}
                            </div>
                        </div>
                    ) : (<>Книжная полка пуста</>)
                    }
                </div>
            </div>
        </>
    );
}

export default Bookshelf;
