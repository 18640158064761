import React from 'react';
import '../css/lead.css';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { HighlightOff } from '@mui/icons-material';

import T from '../lib/translator';
import { useDebounce } from 'use-debounce'
import Selector from './selector';

const Lead = ({title, setSearch = null, selector, setSelector = null}) => {
    const [value, setValue] = React.useState('');

    return (
      <div className="lead">
          <h1>{title}</h1>
          {setSelector && 
            <Selector selector={selector} setSelector={setSelector} options={[
              {'value': 'bookshelf', 'title': T('На книжной полке')},
              {'value': 'liked', 'title': T('Понравившиеся')},
              {'value': 'listened', 'title': T('Прослушанные')},
            ]}/>
          }
          {setSearch && 
            <OutlinedInput
              aria-label={T('Поиск')}
              id="input-with-icon-adornment"
              variant="outlined"
              sx={{marginTop: '8px;', marginRight: '0px', width: '320px'}}
              value={value}
              onChange = {(event) => {setValue(event.target.value); setSearch(event.target.value)}}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                value &&
                    <InputAdornment position="end">
                        <HighlightOff sx={{cursor: 'pointer'}} onClick={_ => {setValue(''); setSearch('');}}/>
                    </InputAdornment>
                
              }
            />
          }
      </div>
    );
};

export default Lead;