import React, { Fragment } from 'react'
import PlaylistPlayer from './playlistplayer';
import '../css/player.css';
import T from '../lib/translator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faCirclePause, faFastForward, faRotateForward, faBackwardFast, faCircle, faRotateBack, faFastBackward } from '@fortawesome/free-solid-svg-icons';
import Timer from './timer';
import { LinearProgress } from '@mui/material';
import Fragments from './fragments';
import { registered } from '../lib/api';
import BookActions from './bookactions';
import {add as addToBookshelf, remove as removeFromBookshelf} from '../lib/bookshelf.ts';
import {add as addBookmark, remove as removeBookmark} from '../lib/bookmarks.ts';
import {like as likeIt} from '../lib/like.ts';

const Player = ({book, track, position}) => {
    const ref = React.useRef(null);
    const [playlist, setPlaylist] = React.useState([]);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [currentTrackId, setCurrentTrackId] = React.useState(0);
    const [currentPosition, setCurrentPosition] = React.useState(0);
    const [totalTime, setTotalTime] = React.useState(0);
    const [rate, setRate] = React.useState(1);
    const [bookshelfed, setBookshelfed] = React.useState(false);
    const [bookmarks, setBookmarks] = React.useState([]);
    const [like, setLike] = React.useState();

    React.useEffect(() => {
        if (!book) return;
        const files = registered() ? 
                //book.files.split(',').map((_, i) => {return {'url': 'https://kitobplus.uz/books/' + book.id.toString().padStart(5, '0') + '/' + (i + 1).toString().padStart(4, '0') + '.mp3', 'length': parseFloat(_)};})
                //book.files.split(',').map((_, i) => {return {'url': 'http://html5audio/gh/xstream.php?books_id=' + book.id + '&tracks_id=' + (i + 1), 'length': parseFloat(_)};})
                book.files.split(',').map((_, i) => {return {'url': '/drm/xstream.php?books_id=' + book.id + '&tracks_id=' + (i + 1), 'length': parseFloat(_)};})
            :
                [{url: 'https://kitobplus.uz/books/' + book.id.toString().padStart(5, '0') + '.mp3', length: 180}]
        ;
        

        //console.log(files);
        if (book?.rate) {
            setRate(Number(book.rate));
        }

        setPlaylist(files);
        setTotalTime(files.reduce((sum, item) => sum + item.length, 0));

        setBookshelfed(book?.bookshelf_dt !== null);
        if (book?.bookmarks) {
            setBookmarks(book.bookmarks);
        }

        setLike(book?.like);
    }, [book, setPlaylist]);

    const onProgress = (playing, trackId, trackPosition) => {
        setIsPlaying(playing);
        setCurrentTrackId(trackId);
        let trackOffset = 0;
        for (let i = 0; i < trackId; i++) {
            trackOffset += playlist[i].length;
        }
        setCurrentPosition(trackOffset + trackPosition);
    };

    const escFunction = (event) => {
        if (event.key === "Escape") {
            ref.current.playPause();
        }
    }

    React.useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return (() => {
            document.removeEventListener("keydown", escFunction, false);
        });
    }, []);

    const saveBookshelfed = (_) => {
        const func = (_ ? addToBookshelf : removeFromBookshelf);
        setBookshelfed(_);
        func(book.id).catch(e => {setBookshelfed(!_); alert(e);});
    };
    
    const addBookmarkAction = () => {
        addBookmark(book.id, ref.current.currentTrack(), ref.current.currentPosition()).then(_ => setBookmarks(_)).catch(e => alert(e));
    };

    const removeBookmarkAction = (track, position) => {
        removeBookmark(book.id, track, position).then(_ => setBookmarks(_)).catch(e => alert(e));
    };

    const saveLike = (_) => {
        let previos_like = _;
        setLike(_);
        likeIt(book.id, _).catch(e => setLike(previos_like));
    }
    
    return (
        <div className="player" style={{
            backgroundImage: 'url(https://kitobplus.uz/pics/' + (book.pics_ext ? 'books/' + book.id + '.' + book.pics_ext : 'book.png'),
        }}>
            <PlaylistPlayer book={book} track={book?.track} position={book?.position} rate={book?.rate} ref={ref} playlist={playlist} onProgress={onProgress}/>

            <div style={{width: 320, height: 288, alignItems: 'center', justifyContent: 'center', display: 'flex'}}><h3 style={{color: 'transparent'}}>{T('Проигрыватель')}</h3></div>
            <div className="playerControlsContainer">
                <div className="playerControls">
                    <button onClick={() => ref.current.previosTrack()} aria-label={T('Предыдущий фрагмент')}><FontAwesomeIcon style={{height: 42}} color="#ffb27d" icon={faFastBackward} /></button>
                    <button onClick={() => ref.current.stepBackward()} aria-label={T('Назад на 30 секунд')}><FontAwesomeIcon style={{height: 42}} color="#ffb27d" icon={faRotateBack} /></button>
                    <button onClick={() => ref.current.playPause()} aria-label={T('Пауза/Воспроизведение')}><FontAwesomeIcon style={{height: 64}} color="#ffb27d" icon={isPlaying ? faCirclePause : faPlayCircle} /></button>
                    <button onClick={() => ref.current.stepForward()} aria-label={T('Вперёд на 30 секунд')}><FontAwesomeIcon style={{height: 42}} color="#ffb27d" icon={faRotateForward} /></button>
                    <button onClick={() => ref.current.nextTrack()} aria-label={T('Следующий фрагмент')}><FontAwesomeIcon style={{height: 42}} color="#ffb27d" icon={faFastForward} /></button>
                </div>
            </div>
            <div style={{flex: 1, marginTop: -8, paddingRight: 4, display: 'flex', justifyContent: 'flex-end'}}>
                <button aria-label={T('Скорость')} onClick={_ => setRate(ref.current.switchRate(rate))} className="rateButton">{rate == Math.round(rate) ? rate + '.00' : rate.toString().padEnd(4, '0')}X</button>
            </div>
            <div className="playerPlaybackPosition">
                <div style={{marginTop: 4, flex: 1}}>
                        <LinearProgress
                            variant={totalTime ? 'determinate' : 'indeterminate'}
                            value={totalTime ? Math.round(100 * currentPosition / totalTime) : undefined}
                            sx={{
                                backgroundColor: "#00aec7",
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: '#ffb27d'
                                }
                            }}
                        />
                </div>
                <div style={{display: 'flex', justifyContent: 'stretch', paddingLeft: 8, paddingRight: 8}}>
                    <div tabIndex={0} role='timer' style={{flex: 1}}><Timer value={currentPosition} /></div>
                    <div role="status" tabIndex={0} className="playerTrack">
                        <span style={{marginLeft: 4, color: '#555', fontFamily: 'Segoe UI Mono', fontSize: '13pt',}}>{currentTrackId + 1}</span>
                        <span style={{color: '#999', fontFamily: 'Segoe UI Mono', fontSize: '13pt',marginLeft: 4, marginRight: 4}}>/</span>
                        <span style={{color: '#555', fontFamily: 'Segoe UI Mono', fontSize: '13pt',}}>{playlist.length}</span>
                    </div>
                    <div tabIndex={0} role='timer' style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}><Timer value={totalTime}/></div>
                </div>
            </div>
            {registered() && <BookActions book={book} bookshelfed={bookshelfed} saveBookshelfed={saveBookshelfed} bookmarks={bookmarks} addBookmark={addBookmarkAction} like={like} setLike={saveLike}/>}
            <Fragments currentTrack={currentTrackId} playTrack={ref.current?.playTrack} playlist={playlist} bookmarks={bookmarks} removeBookmark={removeBookmarkAction}/>
        </div>
    );
};

export default Player;
