import React from 'react';
import '../css/fragments.css';
import T from  '../lib/translator';
import Timer from './timer';
import { registered } from '../lib/api';

const Fragments = ({currentTrack, playTrack, playlist, bookmarks, removeBookmark}) => {
    const [tab, setTab] = React.useState(0);

    const getAbsolutePosition = (track, position) => {
        let offset = 0;
        for (let i = 0; i < track; i++) {
            offset += playlist[i].length;
        }
        return offset + (position / 1000);
    }
    return (
        <div className='fragmentsContainer'>
            <div className='fragmentsHeader'>
                <button aria-label={T('Фрагменты') + ' (' + playlist.length + ')' + (tab === 0 ? ' выбрано.' : '')} onClick={() => setTab(0)} className={tab === 0 ? 'fragmentsTitle fragmentsActive' : 'fragmentsTitle'}>{T('Фрагменты') + ' (' + playlist.length + ')'}</button>
                <button aria-label={T('Закладки') + ' (' + bookmarks.length + ')' + (tab === 1 ? ' выбрано.' : '')} onClick={() => setTab(1)} className={tab === 1 ? 'fragmentsTitle fragmentsActive' : 'fragmentsTitle'}>{T('Закладки') + ' (' + bookmarks.length + ')'}</button>
            </div>
            <div className='fragments'>
                <table border={0} style={{borderCollapse: 'collapse', flex: 1, width: '100%'}}>
                    <tbody>{(tab === 0 ? playlist : bookmarks).map((_, i) => {return (
                        <tr key={i} style={tab === 0 && currentTrack === i ? {backgroundColor: '#00aec7'} : {}} >
                            <td
                                data-track={tab === 0 ? i : _.track}
                                data-position={tab === 0 ? 0 : _.position}
                                onClick={(function(i) {return function() {playTrack(tab === 0 ? i : bookmarks[i].track, tab === 0 ? 0 : bookmarks[i].position);};})(i)} 
                                >{registered() ? (tab === 0 ? T('Фрагмент') : T('Закладка')) + ' ' + (i + 1) : T('Ознакомительный фрагмент')}</td>
                            <td
                                data-track={tab === 0 ? i : _.track}
                                data-position={tab === 0 ? 0 : _.position}
                                onKeyDown={(e) => {if (e.key === 'Enter') playTrack(e.target.getAttribute('data-track'), e.target.getAttribute('data-position'));}}                                
                                key={i+1} 
                                onClick={(function(i) {return function() {console.log(bookmarks[i].track); console.log(bookmarks[i].position); playTrack(tab === 0 ? i : bookmarks[i].track, tab === 0 ? 0 : bookmarks[i].position / 1000);};})(i)} 
                                tabIndex={0} 
                                aria-label={(tab === 0 ? T('Фрагмент') : T('Закладка'))+ ' ' + (i + 1)}
                                align='right'><Timer value={tab === 0 ? _.length : getAbsolutePosition(_.track, _.position)}/></td>
                                {tab === 1 && <td width={16}><button data-track={_.track} data-position={_.position} aria-label={T('Удалить закладку')} onClick={_=>removeBookmark(_.target.getAttribute('data-track'), _.target.getAttribute('data-position'))} style={{border: 0, backgroundColor: 'transparent'}}>❌</button></td>}
                        </tr>);})}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default Fragments;