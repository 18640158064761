import React from 'react';
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import T from '../lib/translator';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import '../css/form.css';
import { Button } from '@mui/material';
import { getUser, logout } from '../lib/api';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateField } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import ImageInput from './imageinput';
import { register } from '../lib/auth.ts';

const Register = () => {
    const [value, setValue] = React.useState()
    const [isCorrect, setIsCorrect] = React.useState()
    const [smsSended, setSmsSended] = React.useState(false)
    const [debugCode, setDebugCode] = React.useState();
    const [error, setError] = React.useState()
    const [code, setCode] = React.useState('')
    const image1Ref = React.useRef(null);
    const image2Ref = React.useRef(null);
    const [name, setName] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [birthday, setBirthday] = React.useState(null);

    React.useEffect(() => {
        if (!getUser() || getUser().state !== 0 || !getUser().phone) {
            logout();
            window.location = '/login';
        }
    }, []);

    const processRegister = () => {
/*        
        console.log(name);
        console.log(birthday);
        console.log(address);
        console.log(image1Ref.current.files.length);
        console.log(image2Ref.current.files.length);
*/
        if (!name || !name.length || !birthday || !address || !address.length || !image1Ref.current.files.length || !image2Ref.current.files.length) {
            alert(T('Пожалуйста, заполните все поля'));
            return;
        }

       register(getUser().phone.toString(), name, birthday, address, image1Ref.current.files[0], image2Ref.current.files[0]).then(_ => {
            window.location = '/profile';
       }).catch(_ => alert(_));
    };

    const changeBirthday = (_) => {
        console.log(_);
        if (isNaN(_.$D)) {setBirthday(null); return;}
        const bd = _.$y.toString().padStart(4, '0') + '-' + _.$M.toString().padStart(2, '0') + '-' + _.$D.toString().padStart(2, '0');
        console.log(bd);
        setBirthday(bd);
    }

    return (
        <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div className='form' style={{width: 320}}>
                <div className='formHeader'>
                    <h1>Регистрация</h1>
                </div>
                    <div className='formBody'>
                        <TextField id="outlined-basic" onChange={(_) => setName(_.target.value)} required={true} label="ФИО" variant="outlined" />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateField onChange={changeBirthday} label="Дата рождения" format="DD.MM.YYYY"/>
                        </LocalizationProvider>
                        <TextField id="outlined-basic" onChange={(_) => setAddress(_.target.value)} required={true} label="Адрес" variant="outlined" />
                        <ImageInput ref={image1Ref} title={T('Загрузите копию паспорта')}/>
                        <ImageInput ref={image2Ref} title={T('Загрузите копию справки об инвалидности')}/>
                    </div>
                    <div className='fromFooter'>
                        <Button variant="contained" color='primary' onClick={processRegister}>Продолжить</Button>
                    </div>
                    
            </div>
        </div>
    );
}

export default Register;