import React from 'react';
import '../css/book.css';
import { Link } from 'react-router-dom';
import T from '../lib/translator';

const Book = ({book}) => {
    return (
        <Link style={{textDecoration: 'none'}} to={"/books/" + book.id} state={{ book: book }} >
            <div className="book">
                <img className="bookImage" alt={T("Обложка книги")} src={'https://kitobplus.uz/pics/' + (book.pics_ext ? 'books/' + book.id + '.' + book.pics_ext : 'book.png')}/>
                <div className="bookName">
                    <div className="bookAuthor">{book.authors_name}</div>
                    <div className="bookTitle">{book.title}</div>
                    <div className="bookDictor">
                        <img alt={T("Иконка диктора")} src={"https://kitobplus.uz/images/dictor" + (book?.dictors_name && book.dictors_name.indexOf(',') > 0 ? 's' : '') + '.png'} />
                        <div>{book.dictors_name}</div>
                    </div>
                </div>
            </div>
        </Link>
    )
};

export default Book;